html, body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

html {
	background: #fff;
    -webkit-font-smoothing: subpixel-antialiased;
}

@media (prefers-color-scheme: dark) {
	html {
		background: #0f0f0f;
	}
}

.background {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	image-rendering: crisp-edges;
	image-rendering: pixelated;
	pointer-events: none;
}

.wrapper {
	width: 100%;
	height: 100%;
	display: flex;
}

.content {
	text-align: center;
	margin: auto;
}

.title, .subtitle {
	font-family: barlow, sans-serif;
	font-style: italic;
	margin: 0;
	padding: 0;
}

.title {
	font-size: 38px;
	font-weight: 800;
	letter-spacing: -0.03em;
}

.underscore {
	letter-spacing: -0.11em;
	line-height: 1em;
	position: relative;
	left: -4px;
}

.subtitle {
	font-size: 25px;
	font-weight: 500;
	letter-spacing: -0.05em;
	line-height: 1em;
}

.link {
	transition: opacity 0.2s;
	text-decoration: none;
}

.title .link {
	color: #000;
}

@media (prefers-color-scheme: dark) {
	.title .link {
		color: #fff;
	}
}

.title .link:hover {
	opacity: 0.5;
}

.subtitle .link {
	color: #555;
}

@media (prefers-color-scheme: dark) {
	.subtitle .link {
		color: #888;
	}
}

.subtitle .link:hover {
	opacity: 0.5;
}
